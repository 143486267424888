import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Image from '../../components/Image';
import Hero from '../../components/Hero';
import Button from '../../components/Button';
import { navigate } from 'gatsby-link';

const CareersPage = () => (
  <Layout title="Careers">
    <Hero
      className="hero--careers"
      iconFile="img-coop-careers-illustration.png"
      banner={true}
      heroBanner="Cooperative Careers"
    >
      <h2>Cultivating Career Connections</h2>
      <p>
        Agricultural cooperatives bring together a wide range of careers and
        related industries to help feed the world. Go inside the day-to-day
        lives of real-world professionals working behind-the-scenes for and with
        co-ops, and show students the diverse skills that can be harnessed to
        find success in agriculture and beyond.
      </p>
    </Hero>
    <Section className="pt-0">
      <Container>
        <Row>
          <Column large={6}>
            <p>
              This series introduces students to a wide variety of in-demand
              career paths that are essential to maintaining a successful
              agricultural co-op.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container>
        <Row className="pb-2">
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-michaela.png'}
              onClick={() => navigate('/career-profiles/truck-driver/')}
            />
            <h4>Michaela Wendlandt</h4>
            <p>
              <em>Truck Driver</em>
            </p>
            <p>
              Find out how Michaela came full circle working for a co-op after
              having grown up on a farm, using her mechanical-mindedness and
              love for new challenges.
            </p>
            <Button to="/career-profiles/truck-driver/">Learn More</Button>
          </Column>
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-barbara.png'}
              onClick={() => navigate('/career-profiles/nutrition/')}
            />
            <h4>Barbara Carruthers</h4>
            <p>
              <em>Animal Nutritional Consultant</em>
            </p>
            <p>
              Did you know farm animals have nutritionists? Discover the
              combination of science and loving care that Barbara puts into
              maintaining healthy diets.
            </p>
            <Button to="/career-profiles/nutrition/">Learn More</Button>
          </Column>
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-evan.png'}
              onClick={() => navigate('/career-profiles/business-analyst/')}
            />
            <h4>Evan Sieling</h4>
            <p>
              <em>Senior Business Analyst, IT</em>
            </p>
            <p>
              Learn about the cutting-edge field of Precision Agriculture from
              Evan, who helps farmers translate the insights of software tools
              to make data-driven farming decisions.
            </p>
            <Button to="/career-profiles/business-analyst/">Learn More</Button>
          </Column>
        </Row>
        <Row className="pb-2">
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-michelle-adams.jpg'}
              onClick={() =>
                navigate('/career-profiles/logistics-superintendent/')
              }
            />
            <h4>Michelle Adams</h4>
            <p>
              <em>Logistics Superintendent</em>
            </p>
            <p>
              Go inside the many roles involved in managing logistics in an
              export grain terminal, from maintaining schedules for shipments to
              managing transportation to organizing materials and products.
            </p>
            <Button to="/career-profiles/logistics-superintendent/">
              Learn More
            </Button>
          </Column>
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-kris-stone.jpg'}
              onClick={() =>
                navigate('/career-profiles/reliability-maintenance-leader/')
              }
            />
            <h4>Kris Stone</h4>
            <p>
              <em>Reliability & Maintenance Leader</em>
            </p>
            <p>
              Explore how preventive and predictive maintenance help ensure that
              terminal operations will run smoothly and that schedules and
              deadlines will be met.
            </p>
            <Button to="/career-profiles/reliability-maintenance-leader/">
              Learn More
            </Button>
          </Column>
          <Column medium={4}>
            <Image
              className="career__image"
              filename={'img-career-ryan-boese.jpg'}
              onClick={() => navigate('/career-profiles/grain-trader/')}
            />
            <h4>Ryan Boese</h4>
            <p>
              <em>Grain Trader</em>
            </p>
            <p>
              Discover how the complex global food supply chain intersects with
              the world of person-to-person business transactions by exploring
              the role of a Grains Trader.
            </p>
            <Button to="/career-profiles/grain-trader/">Learn More</Button>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default CareersPage;
